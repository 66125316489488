import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";

import Util from "../../helpers/util";
import Language from "../../helpers/language";
import parse from "html-react-parser";
import { AnswerType } from "../../helpers/consts";

export const IncorrectAnswerPopup = (props) => {
    const {
        question,
        explanation,
        points_earned,
        correct_answer,
        has_answer,
        onClose,
    } = props;

    const is_empty =
        explanation == undefined ||
        explanation == null ||
        !("info" in explanation) ||
        (explanation.info || "").trim() == "";

    useEffect(() => {
        if (is_empty) {
            onClose();
        }
        // scrollableContainerRef.current.scrollTop = 0;
    }, []);

    const close_ref = useRef(null);
    const scrollableContainerRef = useRef(null);
    useEffect(() => {
        if (close_ref.current != null) {
            close_ref.current.focus();
        }
        if ( scrollableContainerRef.current != null) {
            scrollableContainerRef.current.scrollTop = 0;
        }
    });

    //Possible titles
    const titles = [
        Language.getSentenceCase("Oops, you missed."),
        Language.getSentenceCase("Oops, that's not right."),
        Language.getSentenceCase("Sorry, that's incorrect."),
    ];
    const [title_idx, setTitleIdx] = useState(
        Math.round(Math.random() * (titles.length - 1))
    );

    let points_text = null;
    console.log( has_answer, points_earned )
    if (has_answer && !Util.isbool(points_earned) && points_earned > 0) {
        points_text =
            Language.getSentenceCase("earned") +
            " " +
            points_earned +
            " " +
            (points_earned != 1
                ? Language.getString("points")
                : Language.getString("point"));
    }

    const empty = () => {};

    const show_ans =
        question.answer_type != AnswerType.Checkbox &&
        question.answer_type != AnswerType.Slider;

    //Render nothing
    if (is_empty) {
        return <></>;
    }

    return (
        <div className="up-popup__container">
            <div className="up-popup up-popup--incorrect">
                <div className="up-popup__icon-wrapper">
                    <div className="up-popup__icon-wrapper--cropped"></div>
                </div>

                <div className="up-popup__icon">
                    <svg
                        width="25.47"
                        height="25.47"
                        viewBox="0 0 12 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z"
                            fill="currentColor"
                        ></path>
                    </svg>

                    <svg
                        className="up-popup__icon--shadow"
                        width="25.47"
                        height="25.47"
                        viewBox="0 0 12 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
                <div
                    className="up-popup__scrollable-container"
                    ref={scrollableContainerRef}
                >
                    <h3
                        className="up-popup__heading"
                        style={{ marginTop: "0px" }}
                    >
                        {titles[title_idx]}
                    </h3>
                    <p className="up-popup__p">
                        {show_ans && (
                            <span>
                                <em>
                                    {Language.getSentenceCase(
                                        "Expected response"
                                    )}
                                    :
                                </em>
                                &nbsp; {correct_answer}
                                <br />
                                <br />
                            </span>
                        )}
                        {parse(
                            Util.hyperlink(explanation.info).replace(
                                /([a-zA-Z]{2})\.(?=\s)/gi,
                                "$1.\n"
                            )
                        )}
                        {points_text ? (
                            <span>
                                <br />
                                <br />
                                {points_text}
                            </span>
                        ) : null}
                    </p>
                              </div>
      
                    <button
                        ref={close_ref}
                        className="h-10 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-drip7 ring-1 ring-inset ring-drip7 hover:bg-gray-50 hover:brightness-105"
                        disabled={points_earned == false}
                        onClick={!Util.isbool(points_earned) ? onClose : empty}
                    >
                        {Language.getTitleCase("close")}
                    </button>
            </div>
        </div>
    );
};
