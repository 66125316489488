import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {
    Modal,
    Button,
    FormText,
    FormGroup,
    FormLabel,
    FormControl
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'

import { useStore } from "../../store";
import { AuthenticationType, Notification } from "../../helpers/consts";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Modal } from "../../modals/drip7_modal";
import { SSOConfigModal } from '../../modals/admin/sso_config_modal';
import { SCIMConfigModal } from "../../modals/admin/scim_config_modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Drip7Button } from "../../components/drip7_button";
import { Drip7Input } from "../../components/drip7_input";
import { Drip7Combobox } from "../../components/drip7_combobox";
import { Drip7Dropdown } from "../../components/drip7_dropdown";
import { Drip7Checkbox } from "../../components/drip7_checkbox";


const LOGINS = {
    1: Language.getTitleCase('user/password'),
    2: Language.getTitleCase('azure sso')
}


export const TenantSettingsTab = props => {
    const { initial_info, reload, onChanged, showToast, tenant_uid, tenants } = props;

    const [state, setState] = useState({
        changed: false,
        selected_idx: 0,
        show_sso_groups: false,
        show_scim_modal: false,
        info: props.initial_info,
    })
    const { changed, show_sso_groups, show_scim_modal } = state;
    const info = state.info || {}

    const { usr_info } = useStore()

    useEffect(() => {
        //If is the same id, and the reload hasn't been changed, we don't want to change anything
        if ( state.info?.uid === initial_info.uid || !reload) {
            return
        }

        //If the props inital_info is empty, don't change anything
        if (Object.keys(initial_info).length === 0) {
            return
        }

        //Setup my state info based on props
        const changed = initial_info.uid === "create_tenant"
        const info = initAuthConfig(initial_info)
        setState(prev => ({...prev, changed, info }))
    }, [reload])

    const initAuthConfig = (info) => {
        let { auth_config } = info.tenant_sso;

        //set up some defaults, if necessary
        if (!auth_config) auth_config = {};
        if (!auth_config.auth) auth_config.auth = {};
        if (!auth_config.auth.clientId) auth_config.auth.clientId = "";
        if (!auth_config.auth.authority)
            auth_config.auth.authority =
                "https://login.microsoftonline.com/{your_tenant_id}";
        if (!auth_config.auth.redirectUri)
            auth_config.auth.redirectUri =
                "https://" + info.subdomain + ".drip7.com";
        if (!auth_config.cache)
            auth_config.cache = { storeAuthStateInCookie: false };
        if (!auth_config.cache.cacheLocation)
            auth_config.cache.cacheLocation = "sessionStorage";
        info.tenant_sso.auth_config = auth_config;

        return info;
    }

    const handleSelectAuthentication = (e) => {
        const id = Object.keys(LOGINS).find(key => LOGINS[key] === e.target.value)
        var info = { ...info,
            auth_type: id
        };
        if (id === AuthenticationType.AZURE_SSO) {
            info = initAuthConfig(info);
        }
        setState(prev => ({...prev, info }))

        //Store the value
        onChanged(info);
    }

    const handleSSOUpdate = (tenant_sso) => {
        info.tenant_sso = tenant_sso
        setState(prev => ({...prev, info }))
        onChanged(info);
    }

    const handleSSOModal = () => {
        setState(prev => ({ ...prev, show_sso_groups: !show_sso_groups }))
    }

    const handleSCIMUpdate = (tenant) => {
        info.bearer_token = tenant.bearer_token;
        setState(prev => ({...prev, info }))
        onChanged(info)
    }

    const handleSCIMModal = () => {
        setState(prev => ({...prev, show_scim_modal: !show_scim_modal }))
    }

    const handleChange = (e) => {
        info[e.target.id] = e.target.value

        //Store the value
        setState(prev => ({...prev, info }))
        onChanged(info);
    }

    const handleChecked = (e) => {
        if (e.target.id == 'trial_remaining') {
            info[e.target.id] = e.target.checked ? 30 : null
        }
        else {
            info[e.target.id] = e.target.checked
        }

        //Store the value
        setState(prev => ({...prev, info }))
        onChanged(info)
    }

    const handleCopyClipboard = (url) => {
        navigator.clipboard.writeText(url)
        showToast("Copied URL", "successful")
    }

    const handleFetchManifest = () => {
        Util.fetch_js( "/tenant/get_outlook_manifest/", { domain: info.subdomain },
            js => {
                window.location.href = js.url
            },
            (reason, code) => {
                showToast(reason, 'failure')
            }
        )
    }

    //const { info, show_sso_groups, show_scim_modal } = this.state;
    //const { tenant_uid, tenants, usr_info, showToast } = this.props;
    const login_title = LOGINS[info.auth_type];

    const public_leaderboard_url = `https://${info.subdomain}.drip7.com/leaderboard/${info.uid}/`

    const notify_title = info.notify //(info.notify == Notification.NONE || info.notify == null || info.notify == undefined)? "user specified": info.notify

    const demo_tenant = tenants.find( x => x.uid == info.demo_tenant_uid ) || { name: Language.getTitleCase("No demo tenant") }

    const can_present = (tenant_uid != usr_info?.tenant?.uid)

    return (<div className="space-y-10 divide-y divide-gray-900/10 mt-6">
        <div
            className="grid grid-cols-1 gap-x-8 gap-y-8 sm:pr-8 md:grid-cols-3">
            <div className="px-4 sm:px-0">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                    {Language.getTitleCase( 'main information' )}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                    {Language.getSentenceCase( 'Main tenant information' )}
                </p>
            </div>

            <form
                className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="px-4 py-6 sm:p-8">
                    <div
                        className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <Drip7Input
                                name='company-name'
                                label={Language.getTitleCase( 'company name' )}
                                placeholder={Language.getTitleCase( 'company name' )}
                                onChange={handleChange}
                                id='name'
                                value={info.name}
                            />
                        </div>

                        <div className="sm:col-span-4">
                            <Drip7Input
                                name='subdomain'
                                label={Language.getTitleCase( 'subdomain' )}
                                placeholder={Language.getTitleCase( 'subdomain' )}
                                onChange={handleChange}
                                id='subdomain'
                                value={info.subdomain}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div
            className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 sm:pr-8 md:grid-cols-3">
            <div className="px-4 sm:px-0">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                    {Language.getTitleCase( 'support information' )}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                    {Language.getSentenceCase( 'Contact information for support' )}
                </p>
            </div>

            <form
                className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="px-4 py-6 sm:p-8">
                    <div
                        className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <Drip7Input
                                name='support-email'
                                label={Language.getTitleCase( 'email' )}
                                placeholder={Language.getSentenceCase( 'email for support' )}
                                onChange={handleChange}
                                id='support_email'
                                value={info.support_email}
                            />
                        </div>

                        <div className="sm:col-span-4">
                            <Drip7Input
                                name='support_email_name'
                                label={Language.getTitleCase( 'friendly name' )}
                                placeholder={Language.getSentenceCase( 'friendly name' )}
                                onChange={handleChange}
                                id='support_email_name'
                                value={info.support_email_name}
                            />
                        </div>

                        <div className="sm:col-span-3">
                            <Drip7Input
                                type='tel'
                                name='support-phone'
                                label={Language.getTitleCase( 'phone' )}
                                placeholder='000-000-0000'
                                id='support_phone'
                                onChange={handleChange}
                                value={info.support_phone}
                            />
                        </div>

                        <div className="sm:col-span-3">
                            <Drip7Input
                                name='support-text'
                                label={Language.getTitleCase( 'SMS' )}
                                placeholder='000-000-0000'
                                onChange={handleChange}
                                id='support_sms'
                                value={info.support_sms}
                            />
                        </div>

                        <div className="sm:col-span-5">
                            <Drip7Input
                                name='support-url'
                                label={Language.getTitleCase( 'Support url' )}
                                placeholder={'https://drip7.com/contact-us/'}
                                onChange={handleChange}
                                id='support_url'
                                value={info.support_url}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 sm:pr-8 md:grid-cols-3">
            <div className="px-4 sm:px-0">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                    {Language.getTitleCase( 'login information' )}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                    {Language.getSentenceCase( 'Configure login information, SCIM, SSO' )}
                </p>
            </div>

            <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="px-4 py-6 sm:p-8">
                    <div
                        className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <label htmlFor="login-type"
                                   className="block text-sm font-normal leading-6 text-gray-700">
                                {Language.getTitleCase( 'login type' )}
                            </label>
                            <Drip7Dropdown
                                name='login-type'
                                label={Language.getTitleCase( 'login type' )}
                                onChange={handleSelectAuthentication}
                                id='auth_type'
                                value={login_title}
                                entries={Object.values( LOGINS )}
                            />
                        </div>

                        {info.auth_type == AuthenticationType.AZURE_SSO && (<>
                            <div className="sm:col-span-3">
                                <Drip7Input
                                    name='client-id'
                                    label={Language.getTitleCase( 'client id' )}
                                    placeholder={Language.getSentenceCase( 'friendly name' )}
                                    onChange={handleChange}
                                    id='auth_clientId'
                                    value={info.auth_config?.auth?.auth_clientId}
                                />
                            </div>

                            <div className="sm:col-span-3">
                                <Drip7Input
                                    name='auth-authority'
                                    label={Language.getTitleCase( 'authority' )}
                                    placeholder='https://login.microsoftonline.com/{your_tenant_id}'
                                    id='auth_authority'
                                    onChange={handleChange}
                                    value={info.auth_config?.auth?.authority}
                                />
                            </div>

                            <div className="sm:col-span-3">
                                <Drip7Input
                                    name='redirect-uri'
                                    label={Language.getTitleCase( 'redirect uri' )}
                                    placeholder='https://play.drip7.com'
                                    onChange={handleChange}
                                    id='auth_redirectUri'
                                    value={info.auth_config?.auth?.redirectUri}
                                />
                            </div>

                            <div className="sm:col-span-3">
                                <Drip7Input
                                    name='auth-cache-location'
                                    label={Language.getTitleCase( 'cache location' )}
                                    placeholder={'sessionStorage'}
                                    onChange={handleChange}
                                    id='cache_cacheLocation'
                                    value={info.auth_config?.cache?.cacheLocation}
                                />
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="auth-store-cookie"
                                       className="block text-md font-normal leading-6 text-gray-700">
                                    {Language.getTitleCase( 'store cookie' )}
                                </label>
                                <Drip7Checkbox
                                    name='auth-store-cookie'
                                    label={Language.getTitleCase( 'store cookie' )}
                                    onChange={handleChange}
                                    id='cache_cacheLocation'
                                    value={info.auth_config?.cache?.cacheLocation}
                                />
                            </div>
                        </>)}
                    </div>
                </div>
            </form>
        </div>


        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 pb-10 sm:pr-8 md:grid-cols-3">
            <div className="px-4 sm:px-0">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                    {Language.getTitleCase( 'settings' )}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                    {Language.getSentenceCase( 'Notifications and tenant configuration' )}
                </p>
            </div>

            <form
                className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                <div className="px-4 py-6 sm:p-8">
                    <div className="max-w-2xl space-y-10">
                        <fieldset>
                            <legend
                                className="text-sm font-semibold leading-6 text-gray-900">By
                                Email
                            </legend>
                            <div className="mt-6 space-y-6">
                                <div className="relative flex gap-x-3">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="comments"
                                            name="comments"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-drip7 focus:ring-drip7"
                                        />
                                    </div>
                                    <div className="text-sm leading-6">
                                        <label htmlFor="comments"
                                               className="font-medium text-gray-900">
                                            Comments
                                        </label>
                                        <p className="text-gray-500">Get
                                            notified when someones posts a
                                            comment on a posting.</p>
                                    </div>
                                </div>
                                <div className="relative flex gap-x-3">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="candidates"
                                            name="candidates"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-drip7 focus:ring-drip7"
                                        />
                                    </div>
                                    <div className="text-sm leading-6">
                                        <label htmlFor="candidates"
                                               className="font-medium text-gray-900">
                                            Candidates
                                        </label>
                                        <p className="text-gray-500">Get
                                            notified when a candidate
                                            applies for a job.</p>
                                    </div>
                                </div>
                                <div className="relative flex gap-x-3">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="offers"
                                            name="offers"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-drip7 focus:ring-drip7"
                                        />
                                    </div>
                                    <div className="text-sm leading-6">
                                        <label htmlFor="offers"
                                               className="font-medium text-gray-900">
                                            Offers
                                        </label>
                                        <p className="text-gray-500">Get
                                            notified when a candidate
                                            accepts or rejects an offer.</p>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend
                                className="text-sm font-semibold leading-6 text-gray-900">Push
                                Notifications
                            </legend>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                These are delivered via SMS to your mobile
                                phone.
                            </p>
                            <div className="mt-6 space-y-6">
                                <div className="flex items-center gap-x-3">
                                    <input
                                        id="push-everything"
                                        name="push-notifications"
                                        type="radio"
                                        className="h-4 w-4 border-gray-300 text-drip7 focus:ring-drip7"
                                    />
                                    <label htmlFor="push-everything"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Everything
                                    </label>
                                </div>
                                <div className="flex items-center gap-x-3">
                                    <input
                                        id="push-email"
                                        name="push-notifications"
                                        type="radio"
                                        className="h-4 w-4 border-gray-300 text-drip7 focus:ring-drip7"
                                    />
                                    <label htmlFor="push-email"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        Same as email
                                    </label>
                                </div>
                                <div className="flex items-center gap-x-3">
                                    <input
                                        id="push-nothing"
                                        name="push-notifications"
                                        type="radio"
                                        className="h-4 w-4 border-gray-300 text-drip7 focus:ring-drip7"
                                    />
                                    <label htmlFor="push-nothing"
                                           className="block text-sm font-medium leading-6 text-gray-900">
                                        No push notifications
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div
                    className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                    <Drip7Button mode="outlined">
                        {Language.getTitleCase( 'discard changes' )}
                    </Drip7Button>
                    <Drip7Button>
                        {Language.getTitleCase( 'save changes' )}
                    </Drip7Button>
                </div>
            </form>
        </div>
    </div>)
    return (<div className="field-container">
            <div className='customization-fields' role='tabpanel'
                 id='tab_panel_main_information'>
                <div>

                    <div className='grid-item'>
                        <label htmlFor='company-name'>
                            <span className='tool-tip__container'>
                                <span className="tool-tip__text">
                                    {Language.getSentenceCase( "appears as tenant name throughout admin page" )}
                                </span>
                                {Language.getTitleCase( 'name' )}&nbsp;
                                <span className='req'>*</span>
                            </span>
                        </label>
                        <input
                            type='text'
                            name='company-name'
                            placeholder={Language.getTitleCase( 'my example company' )}
                            required=''
                            onChange={handleChange}
                            id='name'
                            value={info.name}
                        />

                        <label htmlFor='subdomain'>
                            <span className='tool-tip__container'>
                                <span className="tool-tip__text">
                                    {Language.getSentenceCase( "appears before .drip7.com in the app url - 'company.drip7.com'" )}
                                </span>
                                {Language.getTitleCase( 'subdomain' )}&nbsp;
                                <span className='req'>*</span>
                            </span>
                        </label>
                        <input
                            type='text'
                            name='subdomain'
                            placeholder='ExampleCompany'
                            required=''
                            onChange={handleChange}
                            id='subdomain'
                            value={info.subdomain}
                        />

                        {usr_info.role.toLowerCase() == 'super admin' &&
                            <div className='customization-checkbox'>
                                <input
                                    type='checkbox'
                                    aria-label="checkbox"
                                    name='trial-remaining-tagline'
                                    onChange={handleChecked}
                                    id='trial_remaining'
                                    checked={info.trial_remaining != null}
                                />
                                <span className='checkmark'></span>
                                <span className='tool-tip__container'>
                                <span className="tool-tip__text">
                                    {Language.getSentenceCase( "when checked, the tenant is on a 30-day trial" )}
                                </span>
                            <label htmlFor='trial-remaining-tagline'>
                                {Language.getTitleCase( '30-day trial' )}
                            </label>
                            </span>
                            </div>}

                        <label htmlFor='email-label'>
                            <span className='tool-tip__container'>
                                {/* <span className="tool-tip__text">
                                    {Language.getSentenceCase("appears as tenant name throughout admin page")}
                                </span> */}
                                {Language.getTitleCase( 'email label' )}
                            </span>
                        </label>
                        <input
                            type='text'
                            name='email-label'
                            placeholder={Language.getTitleCase( 'example' ) + "CO"}
                            onChange={handleChange}
                            id='email_label'
                            value={info.email_label}
                        />

                        <label htmlFor='login-tagline'>
                            <span className='tool-tip__container'>
                                {/* <span className="tool-tip__text">
                                    {Language.getSentenceCase("appears as tenant name throughout admin page")}
                                </span> */}
                                {Language.getTitleCase( 'login tagline' )}
                            </span>
                        </label>
                        <input
                            type='text'
                            name='login-tagline'
                            placeholder={Language.getSentenceCase( 'Welcome to example company' )}
                            onChange={handleChange}
                            id='login_tagline'
                            value={info.login_tagline}
                        />
                    </div>

                    <div className="grid-item">
                        <label htmlFor='support-email'>
                        <span className='tool-tip__container'>
                                <span className="tool-tip__text">
                                    {Language.getSentenceCase( "this email will be visible on the login view. If this field is left blank, nothing will appear" )}
                                </span>
                            {Language.getTitleCase( 'support email' )}
                            </span>
                        </label>
                        <input
                            type='email'
                            name='support-email'
                            placeholder={Language.getSentenceCase( 'email for support' )}
                            onChange={handleChange}
                            id='support_email'
                            value={info.support_email}
                        />

                        <label htmlFor='friendly-name'>
                        <span className='tool-tip__container'>
                                <span className="tool-tip__text">
                                    {Language.getSentenceCase( "the name that shows how the sender wants to be identified" )}
                                </span>
                            {Language.getTitleCase( 'friendly name' )}
                            </span>
                        </label>
                        <input
                            type='email'
                            name='support_email_name'
                            placeholder={Language.getSentenceCase( 'friendly name' )}
                            onChange={handleChange}
                            id='support_email_name'
                            value={info.support_email_name}
                        />

                        <label htmlFor='support-phone'>
                            <span className='tool-tip__container'>
                                <span className="tool-tip__text">
                                    {Language.getSentenceCase( "this number will be visible on the login view. if this field is left blank, nothing will appear" )}
                                </span>
                                {Language.getTitleCase( 'support phone' )}
                            </span>
                        </label>
                        <input
                            type='tel'
                            name='support-phone'
                            placeholder='000-000-0000'
                            id='support_phone'
                            onChange={handleChange}
                            value={info.support_phone}
                        />

                        <label htmlFor='support-text'>
                        <span className='tool-tip__container'>
                                <span className="tool-tip__text">
                                    {Language.getSentenceCase( "this number will be visible on the login view. if this field is left blank, nothing will appear")}
                                </span>
                            {Language.getTitleCase('support text')}
                            </span>
                        </label>
                        <input
                            type='text'
                            name='support-text'
                            placeholder='000-000-0000'
                            onChange={handleChange}
                            id='support_sms'
                            value={info.support_sms}
                        />

                        <label htmlFor='support-url'>
                        <span className='tool-tip__container'>
                                <span className="tool-tip__text">
                                    {Language.getSentenceCase("this support center link appears in the user profile drop-down menu")}
                                </span>
                            {Language.getTitleCase('support url')}
                            </span>
                        </label>
                        <input
                            type='text'
                            name='support-url'
                            placeholder={Language.getSentenceCase("Questions? Visit Our Site")}
                            onChange={handleChange}
                            id='support_url'
                            value={info.support_url}
                        />


                    </div>


                </div>
                <div>



                    <div className="grid-item">
                        <div className='customization-checkbox' style={{ margin: "0px"}}>
                            <input
                                type='checkbox'
                                id='public_leaderboard'
                                name='public-leaderboard'
                                onChange={handleChecked}
                                checked={info.public_leaderboard}
                                aria-label='public-leaderboard'
                            />
                            <span className='checkmark'></span>
                            <label htmlFor='public-leaderboard'>
                                <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("this links directly to a view of the leaderboard")}
                                    </span>
                                    {Language.getTitleCase('public leaderboard')}
                                </span>
                            </label>
                        </div>
                        <input
                            type='text'
                            id='leaderboard_url'
                            name='leaderboard_url'
                            aria-label="public leaderboard url"
                            value={public_leaderboard_url}
                            disabled={!info.public_leaderboard}
                            readOnly={true}
                            onClick={() => handleCopyClipboard(public_leaderboard_url)}
                        />

                        <label htmlFor='profile-screen-message'>
                        <span className='tool-tip__container'>
                                {/* <span className="tool-tip__text">

                                    {Language.getSentenceCase("Profile screen message Tool tip")}
                                </span> */}
                            {Language.getTitleCase('Profile Screen Message')}
                            </span>
                        </label>
                        <textarea
                            name='profile-screen-message'
                            placeholder={Language.getSentenceCase('Hello Dear Friend!')}
                            id='profile_screen'
                            value={info.profile_screen}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="grid-item">
                    <div className='dropdown dropdown--attached'>
                            <DropdownButton title={login_title}>
                                {Object.keys(LOGINS).map(k => (
                                    <Dropdown.Item
                                        as='button'
                                        onClick={e => handleSelectAuthentication(k)}
                                        id={k}
                                        key={k}>
                                        {LOGINS[k]}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </div>
                        {info.auth_type == AuthenticationType.AZURE_SSO && (
                            <Drip7Button
                                name='show-sso-modal'
                                onClick={handleSSOModal}>
                                {Language.getTitleCase('configure single sign-on')}
                            </Drip7Button>
                        )}
                        <Drip7Button
                            name='show-scim-modal'
                            onClick={handleSCIMModal}>
                            {Language.getTitleCase('configure scim')}
                        </Drip7Button>
                        {info.auth_type == AuthenticationType.USER_PWD && (
                            <div className='customization-checkbox'>
                                <input
                                    type='checkbox'
                                    aria-label="checkbox"
                                    name='can-register-tagline'
                                    onChange={handleChecked}
                                    id='can_register'
                                    checked={info.can_register}
                                />
                                <span className='checkmark'></span>
                                <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("when checked, the register button will appear on the login page, to allow for registering a new user")}
                                    </span>
                                <label htmlFor='can_register'>
                                    {Language.getTitleCase('Allow anyone to register')}
                                </label>
                                </span>
                            </div>
                        )}
                        <div className='customization-checkbox'>
                            <input
                                type='checkbox'
                                aria-label="checkbox"
                                name='users-can-edit'
                                readOnly
                                id='user_editing'
                                onChange={handleChecked}
                                checked={info.user_editing}
                            />
                            <span className='checkmark'></span>
                            <label htmlFor='user_editing'>
                            <span className='tool-tip__container'>
                                    {/* <span className="tool-tip__text">
                                        {Language.getSentenceCase("__replace__ Tool tip")}
                                    </span> */}
                                {Language.getTitleCase('Users can edit their information')}
                                </span>
                            </label>
                        </div>
                        {info.auth_type == AuthenticationType.USER_PWD &&
                            <div className='customization-checkbox'>
                                <input
                                    type='checkbox'
                                    aria-label="checkbox"
                                    name='users-can-delete'
                                    readOnly
                                    id='allow_delete'
                                    onChange={handleChecked}
                                    checked={info.allow_delete}
                                />
                                <span className='checkmark'></span>
                                <label htmlFor='allow_delete'>
                                <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("when checked, user can have their data completely deleted from our database")}
                                    </span>
                                    {Language.getTitleCase('Users can delete their account and data')}
                                </span>
                                </label>
                            </div>
                        }

                        <div className='customization-checkbox'>
                            <input
                                type='checkbox'
                                aria-label="checkbox"
                                name='allow_anonymity'
                                readOnly
                                id='allow_anonymity'
                                onChange={handleChecked}
                                checked={info.allow_anonymity}
                            />
                            <span className='checkmark'></span>
                            <label htmlFor='allow_anonymity'>
                            <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("when checked, users can optionally be anonymous on the leaderboard, so others can not see their name")}
                                    </span>
                                {Language.getTitleCase('allow leaderboard anonymity')}
                                </span>
                            </label>
                        </div>

                        <div className='customization-checkbox'>
                            <input
                                type='checkbox'
                                aria-label="checkbox"
                                name='reset_points_yearly'
                                readOnly
                                id='reset_points_yearly'
                                onChange={handleChecked}
                                checked={info.reset_points_yearly}
                            />
                            <span className='checkmark'></span>
                            <label htmlFor='reset_points_yearly'>
                            <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("resets all users points to zero annually")}
                                    </span>
                                {Language.getTitleCase('reset points yearly')}
                                </span>
                            </label>
                        </div>

                        {can_present &&
                            <div className='customization-checkbox'>
                                <input
                                    type='checkbox'
                                    name='presentation'
                                    aria-label="checkbox"
                                    readOnly
                                    id='presentation'
                                    onChange={handleChecked}
                                    checked={info.presentation}
                                />
                                <span className='checkmark'></span>
                                <label htmlFor='presentation'>
                                <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("this links directly to a view of the leaderboard")}
                                    </span>
                                    {Language.getTitleCase('presentation mode **Dangerous**')}
                                </span>
                                </label>
                            </div>
                        }

                        <div className='dropdown dropdown--attached'>
                            <label htmlFor='notify'>
                            <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("how often should users get notifications. user specified says that users can choose daily, weekly, or never.")}
                                    </span>
                                {Language.getTitleCase('Notification override')}
                                </span>
                            </label>
                            <DropdownButton id="notify" title={Language.getTitleCase(notify_title)}>
                                {Object.entries(Notification).map( ([key, value]) => (
                                    <Dropdown.Item
                                        as='button'
                                        onClick={handleChange}
                                        id="notify"
                                        key={key}
                                        value={value}>
                                        {Language.getTitleCase(value)}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </div>

                        {tenants.length > 1 &&
                            <div className='dropdown dropdown--attached'>

                                <label htmlFor='demo_tenant_uid'>
                                <span className='tool-tip__container'>
                                    <span className="tool-tip__text">
                                        {Language.getSentenceCase("this only appears for tenants that have multiple subtenants, and specifies which tenant is used to demo the app")}
                                    </span>    {Language.getTitleCase( 'Select a demo tenant for registration' )}
                                </span>
                                </label>
                                <DropdownButton id="demo_tenant_uid"
                                                title={demo_tenant.name}>
                                    <Dropdown.Item
                                        as='button'
                                        onClick={handleChange}
                                        id="demo_tenant_uid"
                                        key={"demo_tenant_key_-1"}
                                        value={""}>
                                        ---
                                    </Dropdown.Item>
                                    {Object.entries( tenants.filter(x => x.uid != tenant_uid ) ).map( ( [key, value] ) => (
                                        <Dropdown.Item
                                            as='button'
                                            onClick={handleChange}
                                            id="demo_tenant_uid"
                                            key={`demo_tenant_key_${key}`}
                                            value={value.uid}>
                                            {value.name}
                                        </Dropdown.Item>) )}
                                </DropdownButton>
                            </div>
                        }
                        <Drip7Button
                            name='outlook_ext_url'
                            className='solid-button'
                            onClick={handleFetchManifest}>
                            {Language.getTitleCase('outlook phish extension manifest')}
                        </Drip7Button>
                    </div>

                </div>

            </div>
            <Drip7Modal>
                {show_sso_groups && (
                    <SSOConfigModal
                        tenant={info}
                        onUpdate={handleSSOUpdate}
                        onClose={handleSSOModal}
                        showToast={showToast}
                    />
                )}
                {show_scim_modal &&
                    <SCIMConfigModal
                        tenant={info}
                        onUpdate={handleSCIMUpdate}
                        onClose={handleSCIMModal}
                        showToast={showToast}
                    />
                }
            </Drip7Modal>
        </div>
    );
}
